export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    perfil_id: [1, 2, 5, 6, 7, 10, 15],
  },
  {
    title: 'Cadastros',
    icon: 'FileTextIcon',
    perfil_id: [2],
    children: [
      {
        title: 'Usuários',
        route: 'list-users',
        perfil_id: [2],
      },
      {
        title: 'Perfis de Usuários',
        route: 'list-perfil-usuario',
        perfil_id: [2],
      },
      {
        title: 'Tipos de Avaliação Clínica',
        route: 'list-avaliacao-clinica',
        perfil_id: [2],
      },
      {
        title: 'Tipos de Acompanhante',
        route: 'list-tipo-acompanhante',
        perfil_id: [2],
      },
      {
        title: 'Tipos de Transporte',
        route: 'list-tipo-transporte',
        perfil_id: [2],
      },
      {
        title: 'Tipos de Auxilio Locomoção',
        route: 'list-tipo-auxilio-locomocao',
        perfil_id: [2],
      },
      {
        title: 'Tipos de Endereços',
        route: 'list-tipo-domicilio',
        perfil_id: [2],
      },
      {
        title: 'Tipos de Unidades de Saúde',
        route: 'list-tipo-unidade-saude',
        perfil_id: [2],
      },
      {
        title: 'Tipos de Veículos',
        route: 'list-tipo-veiculo',
        perfil_id: [2],
      },
      {
        title: 'Tipos de Cuidados de Produtos',
        route: 'list-tipo-cuidado-produtos',
        perfil_id: [2],
      },
      {
        title: 'Tipos de Estabelecimentos',
        route: 'list-tipo-estabelecimento',
        perfil_id: [2],
      },
      {
        title: 'Tipos de Unidades de Medidas',
        route: 'list-tipo-unidade-medida',
        perfil_id: [2],
      },
      {
        title: 'Motivos de Transporte',
        route: 'list-motivo-transporte',
        perfil_id: [2],
      },
      {
        title: 'Natureza de Transporte',
        route: 'list-natureza-transporte',
        perfil_id: [2],
      },
      {
        title: 'Situação do Transporte',
        route: 'list-situacao-transporte',
        perfil_id: [2],
      },
      {
        title: 'Estabelecimentos',
        route: 'list-estabelecimento',
        perfil_id: [2],
      },
      {
        title: 'CIDs',
        route: 'list-cids',
        perfil_id: [2],
      },
      {
        title: 'CBOs',
        route: 'list-cbos',
        perfil_id: [2],
      },
      {
        title: 'Veículos',
        route: 'list-veiculo',
        perfil_id: [2],
      },
      {
        title: 'Pacientes',
        route: 'list-paciente',
        perfil_id: [2],
      },
      {
        title: 'Produtos',
        route: 'list-produtos',
        perfil_id: [2],
      },
      {
        title: 'Categorias de Produtos',
        route: 'list-categoria-produto',
        perfil_id: [2],
      },
      {
        title: 'UF',
        route: 'list-ufs',
        perfil_id: [2],
      },
      {
        title: 'Municipios',
        route: 'list-municipios',
        perfil_id: [2],
      },
      {
        title: 'Unidades de Saúde',
        route: 'list-unidade-saude',
        perfil_id: [2],
      },
      {
        title: 'Cancelamentos',
        route: 'list-cancelamentos',
        perfil_id: [2],
      },
      {
        title: 'Rejeições',
        route: 'list-rejeicoes',
        perfil_id: [2],
      },
    ],
  },
  {
    title: 'Solicitações',
    route: 'list-transporte',
    icon: 'HeartIcon',
    perfil_id: [1, 2, 5, 6, 7, 15],
  },
  {
    title: 'Aprovações',
    route: 'list-aprovacoes',
    icon: 'CheckSquareIcon',
    perfil_id: [2, 7],
  },
  {
    title: 'Programações',
    route: 'list-programacoes',
    icon: 'CalendarIcon',
    perfil_id: [1, 2, 5, 6, 7],
  },
  {
    title: 'Cancelados',
    route: 'list-cancelados',
    icon: 'XIcon',
    perfil_id: [1, 2, 5, 6, 7],
  },
  {
    title: 'Calendário',
    route: 'calendario',
    icon: 'CalendarIcon',
    perfil_id: [1, 2, 5, 6, 7],
  },
  {
    title: 'Configurações',
    route: 'list-configuracoes',
    icon: 'ClipboardIcon',
    perfil_id: [2],
  },
  {
    title: 'Logs',
    route: 'list-logs_genericos',
    icon: 'ClipboardIcon',
    perfil_id: [2],
  },
]
